<template>
  <!-- 视频列表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 视频分类 -->
                <a-col :md="5" :sm="24">
                  <a-form-item label="视频分类">
                    <a-select v-model="queryParam.categoryId" allow-clear placeholder="请选择">
                      <a-select-option v-for="(item, index) in videoTypeList" :key="index" :value="item.status">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

                <!-- 主播昵称 -->
                <a-col :md="5" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <!-- 审核状态 -->
                <a-col :md="5" :sm="24">
                  <a-form-item label="审核状态">
                    <a-select v-model="queryParam.checkResult" allow-clear placeholder="请选择">
                      <a-select-option :value="1">待审核</a-select-option>
                      <a-select-option :value="2">已通过</a-select-option>
                      <a-select-option :value="3">已拒绝</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="4" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                </a-col>
                <a-col :md="24" :sm="24" v-if="hasPerm('video:editBatch')">
                  <a-button type="primary" style="margin-bottom: 15px" @click="openBatchEditForm"
                    >批量编辑基数</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :row-selection="rowSelection"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
          >
            <!-- 审核状态插槽 -->
            <span slot="checkResult" slot-scope="text">
              <a-tag style="line-height: 20px" :color="text == 1 ? 'orange' : text == 2 ? 'green' : 'red'">
                {{ text == 1 ? '待审核' : text == 2 ? '已通过' : text == 3 ? '已拒绝' : '' }}
              </a-tag>
            </span>
            <!-- 发布状态插槽 -->
            <span slot="releaseStatus" slot-scope="text">
              <a-tag style="line-height: 20px" :color="text == 1 ? 'orange' : text == 2 ? 'green' : ''">
                {{ text == 1 ? '草稿' : text == 2 ? '发布' : '' }}
              </a-tag>
            </span>
            <!-- 视频列表状态插槽 -->
            <span slot="delFlag" slot-scope="text, record" @click="clickSwitch(record)">
              <a-switch
                checked-children="启用"
                un-checked-children="禁用"
                v-model="record.checked"
                @change="changeSwitch"
              />
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.detailForm.detail(record)">详情</a>
              <a-divider type="vertical" />
              <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysItemDelete(record)">
                <a>删除</a>
              </a-popconfirm>
              <a-divider type="vertical" v-if="hasPerm('video:edit')" />
              <a @click="$refs.likeForm.show(record)" v-if="hasPerm('video:edit')">编辑点赞基数</a>
              <a-divider type="vertical" v-if="record.checkResult == 1 && record.releaseStatus == 2" />
              <span v-if="record.checkResult == 1 && record.releaseStatus == 2">
                <a-divider type="vertical" />
                <a @click="$refs.oneForm.one({ ids: [record.id], checkResult: '2' })">通过</a>
                <a-divider type="vertical" />
                <a @click="$refs.oneForm.one({ ids: [record.id], checkResult: '3' })">拒绝</a>
              </span>
              <a-divider type="vertical" v-if="record.isRecommend === 2 && hasPerm('recommendVideo:add')" />
              <a @click="$refs.sortForm.one(record)" v-if="record.isRecommend === 2 && hasPerm('recommendVideo:add')"
                >设为推荐视频</a
              >
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            >
            </a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <detail-form ref="detailForm"></detail-form>
    <one-form ref="oneForm" @ok="getList"></one-form>
    <like-form ref="likeForm" @ok="getList"></like-form>
    <batch-form ref="batchForm" @ok="getList"></batch-form>
    <sort-form ref="sortForm" @ok="getList"></sort-form>
  </div>
</template>

<script>
import { videoList, videoListDelete, videoAudit } from '@/api/modular/mallLiving/videoList'
import { recommendPosition, videoClassList } from '@/api/modular/mallLiving/videoClass'
import { addRecommendVideo } from '@/api/modular/mallLiving/recommendVideo'

import detailForm from './detailForm.vue'
import oneForm from './oneForm.vue'
import LikeForm from './likeForm.vue'
import BatchForm from './batchEditForm.vue'
import sortForm from './sortForm.vue'

export default {
  data() {
    return {
      storeId: '',
      columns: [
        {
          title: '序号',
          width: '100px',
          align: 'center',
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '视频标题',
          dataIndex: 'title',
          width: '180px',
          key: 'title',
          align: 'center',
        },
        {
          title: '所属分类',
          align: 'center',
          width: '100px',
          dataIndex: 'categoryName',
          key: 'categoryName',
        },
        {
          title: '发布状态',
          align: 'center',
          width: '80px',
          dataIndex: 'releaseStatus',
          scopedSlots: {
            customRender: 'releaseStatus',
          },
        },
        {
          title: '评论数',
          dataIndex: 'commentNum',
          width: '80px',
          key: 'commentNum',
          align: 'center',
        },
        {
          title: '发布人',
          dataIndex: 'nickName',
          width: '100px',
          key: 'nickName',
          align: 'center',
        },
        {
          title: 'ID号',
          dataIndex: 'idNo',
          width: '150px',
          align: 'center',
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          width: '150px',
          align: 'center',
        },
        {
          title: '审核状态',
          dataIndex: 'checkResult',
          width: '100px',
          align: 'center',
          scopedSlots: {
            customRender: 'checkResult',
          },
        },
        {
          title: '状态',
          dataIndex: 'delFlag',
          width: '100px',
          key: 'delFlag',
          align: 'center',
          scopedSlots: {
            customRender: 'delFlag',
          },
        },
        {
          title: '发布时间',
          dataIndex: 'createTime',
          width: '150px',
          key: 'createTime',
          align: 'center',
        },
        {
          title: '审核时间',
          dataIndex: 'reviewTime',
          width: '150px',
          key: 'reviewTime',
          align: 'center',
        },
        {
          title: '操作',
          width: '350px',
          align: 'left',
          fixed: 'right',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      recordItem: null,
      loading: false,
      queryParam: {
        recommendPositionId: undefined,
      },
      positions: [],
      selectedRowKeys: [],
      selectRowId: [],
      videoTypeList: [], //视频分类数据
    }
  },
  components: {
    detailForm,
    oneForm,
    LikeForm,
    BatchForm,
    sortForm,
  },
  created() {
    if (this.hasPerm('video:edit')) {
      let params1 = {
        title: '点赞数',
        dataIndex: 'likeNum',
        width: '80px',
        key: 'likeNum',
        align: 'center',
      }
      let params = {
        title: '点赞基数',
        dataIndex: 'likeBasicNum',
        width: '180px',
        align: 'center',
      }
      this.columns.splice(3, 0, params1)
      this.columns.splice(4, 0, params)
    } else {
      let params = {
        title: '点赞数',
        dataIndex: 'likeNum',
        width: '80px',
        key: 'likeNum',
        customRender: (text, record) => `${record.likeNum * 1 + record.likeBasicNum * 1}`,
        align: 'center',
      }
      this.columns.splice(3, 0, params)
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectRowId = selectedRows.map((item) => {
            return item.id
          })
        },
      }
    },
  },
  mounted() {
    this.storeId = this.$route.query.id
    this.initTableData()
    this.getVideoClassList() //获取视频分类数据
  },
  methods: {
    setToRecommendVideo(record) {
      addRecommendVideo({ videoId: record.id, sort: 100 }).then((res) => {
        if (res.success) {
          this.$message.success('设置成功')
          this.getList()
        }
      })
    },
    openBatchEditForm() {
      if (this.selectRowId.length > 0) {
        this.$refs.batchForm.show(this.selectRowId)
      } else {
        this.$message.error('请选择要操作的数据')
        return
      }
    },
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    getList() {
      this.loading = true
      this.selectRowId = []
      this.selectedRowKeys = []
      var params = {
        page: this.page,
        queryParam: {
          storeId: this.storeId,
          ...this.queryParam,
        },
      }
      videoList(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    //获取视频分类列表数据
    getVideoClassList() {
      videoClassList({}).then((res) => {
        this.videoTypeList = res.data.map((item) => {
          return {
            status: item.id,
            name: item.name,
          }
        })
      })
    },
    // 删除
    sysItemDelete(record) {
      this.recordItem = record
      this.disableCommodityCate(2) //2:删除
    },
    clickSwitch(record) {
      this.recordItem = record
    },
    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableCommodityCate(delFlag)
      }, 300)
    },
    async disableCommodityCate(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await videoListDelete(params)
      var text = delFlag == 1 ? '启用' : delFlag == 2 ? '删除' : '禁用'
      if (data.code == 200) {
        this.$message.success(this.recordItem.title + ' 已' + text)
        this.getList()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 20px;
  height: 20px;
}
</style>
